






























import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';

import Menu from '@/components/fmh_components/common/Menu/Menu.vue';
import DashboardView from '@/views/Dashboard/components/DashboardView/DashboardView.vue';
import FmhService from '@/views/Dashboard/components/FMHService/FmhService.vue';
import FirstWelcome from '@/views/Dashboard/components/FirstWelcome/FirstWelcome.vue';
import Welcome from '@/views/Dashboard/components/Welcome/Welcome.vue';
import Loader from '@/components/fmh_components/common/Loader/Loader.vue';
import TopTagesgeldZinsen from '@/views/Dashboard/components/TopZinsen/TopTagesgeldZinsen.vue';
import TopFestgeldZinsen from '@/views/Dashboard/components/TopZinsen/TopFestgeldZinsen.vue';

interface User {
  fullName: string;
  email: string;
  isFirstVisit: boolean;
}

@Component({
  components: {
    DashboardView,
    Menu,
    FirstWelcome,
    FmhService,
    Welcome,
    Loader,
    TopTagesgeldZinsen,
    TopFestgeldZinsen,
  },
  methods: {
    ...mapActions({
      fetchTop: 'reports/fetchTop',
    }),
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      loading: 'reports/loading',
    }),
  },
})
export default class Dashboard extends Vue {
  user: User | undefined;
  fetchTop!: () => Promise<Object>;
  dashboardTop!: Object;

  get isFirstVisit() {
    return this.user?.isFirstVisit || true;
  }
  created() {
    this.fetchTop();
  }
}
