


















import { Vue, Prop, Component } from 'vue-property-decorator';

@Component({})
export default class Dialog extends Vue {
  @Prop() message!: String;
  @Prop() isShowModal!: Boolean;
  @Prop() dataCy: String | undefined;

  get model() {
    return this.isShowModal;
  }

  set model(value) {
    this.$emit('change', value);
  }
  handleChangeDialog() {
    this.$emit('change', false);
  }
}
