

































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import { BASE_BLACK_COLOR } from '@/utils/constants';

@Component({
  computed: {
    ...mapGetters({
      dashboardTop: 'reports/getDashboardTop',
    }),
  },
})
export default class TopTagesgeldZinsen extends Vue {
  color = BASE_BLACK_COLOR;
}
