













import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import iframeResize from '@/plugins/iFrameResizer';

import { SUPERSET_BACKEND_URL } from '@/utils/constants';
import { IUser } from '@/interfaces';

@Component({
  components: {},
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
  },
  methods: {
    ...mapActions({
      loginSuperSet: 'auth/loginSuperSet',
    }),
  },
})
export default class DashboardView extends Vue {
  user?: IUser;
  loginSuperSet!: () => Promise<any>;

  get validate() {
    return process.env.VUE_APP_IS_SHOW_CHARTS !== 'false';
  }
  get defaultStyle() {
    const height = window.innerHeight - 310;
    return {
      'min-height': `${height}px`,
    };
  }

  async mounted() {
    if (this.validate) {
      await embedDashboard({
        id: 'dd5b7539-4c74-494d-b7a7-f20c591390f3',
        supersetDomain: SUPERSET_BACKEND_URL,
        mountPoint: document.getElementById('superset')!,
        fetchGuestToken: () => this.loginSuperSet() as Promise<string>,
        dashboardUiConfig: {
          hideTitle: true,
          hideChartControls: false,
          hideTab: false,
          filters: {
            visible: false,
            expanded: false,
          },
        },
        debug: true,
      });
      const iframe = document.getElementsByTagName('iframe')[0];
      if (iframe) {
        iframeResize({}, iframe);
      }
    }
  }
}
