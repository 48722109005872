































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import Dialog from '@/components/fmh_components/common/Dialog/Dialog.vue';

@Component({
  components: { Dialog },
  computed: {
    ...mapGetters({
      permissions: 'auth/getPermissions',
    }),
  },
})
export default class Welcome extends Vue {
  @Prop() user: Object | undefined;

  modal = false;
  permissions: Array<string> | undefined;
  pages = [
    {
      title: 'Zins-Chart erstellen',
      permission: 'rest',
      link: '/chart-generator',
    },
    {
      title: 'Baugeld tracken',
      permission: 'mortgage.liveData',
      link: '/baugeld',
    },
    {
      title: 'Tagesgeld-Vergleich erstellen',
      permission: 'callMoney.liveData',
      link: '/tagesgeld',
    },
  ];
  reports = [
    {
      title: 'Bezeichung Auswertung 01',
      link: 'tagesgeld/report/52e8fc14-76a7-43c0-8f60-f8ffabe37831',
    },
    {
      title: 'Bezeichung Auswertung 02',
      link: 'tagesgeld/report/a1931d2e-849f-4d87-ae96-60bf4629f6ff',
    },
    {
      title: 'Bezeichung Auswertung 03',
      link: 'tagesgeld/report/a03e50bc-a089-49b7-ba04-70b9ac72e7be',
    },
  ];
  validate(page) {
    if (this.permissions?.includes(page.permission)) {
      this.$router.push(page.link);
    }
    this.modal = true;
  }

  handleChangeModal(val) {
    this.modal = val;
  }
}
