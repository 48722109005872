<template>
  <v-card>
    <v-card-title>
      <v-row justify="space-between">
        <v-col> Festgeld Top 5 </v-col>
        <v-col class="d-flex justify-end align-center">
          <v-btn-toggle
            v-model="toggleOne"
            shaped
            mandatory
            active-class="selected"
            class="top-anbieter-toggle"
          >
            <v-btn small value="kurz">{{
              $t('general.actionBtn.shortTerm')
            }}</v-btn>
            <v-btn small value="lang">{{
              $t('general.actionBtn.longTerm')
            }}</v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="base-bold-color inter">
              {{ $t('dashboard.topAnbieter.rang').toUpperCase() }}
            </th>
            <th class="base-bold-color inter">
              <span v-if="toggleOne === 'lang'">
                {{ $t('dashboard.topAnbieter.2y').toUpperCase() }}</span
              >
              <span v-else>{{
                $t('dashboard.topAnbieter.3m').toUpperCase()
              }}</span>
            </th>
            <th class="base-bold-color inter">
              <span v-if="toggleOne === 'lang'">
                {{ $t('dashboard.topAnbieter.5y').toUpperCase() }}</span
              >
              <span v-else>
                {{ $t('dashboard.topAnbieter.6m').toUpperCase() }}</span
              >
            </th>
            <th class="base-bold-color inter">
              <span v-if="toggleOne === 'lang'">
                {{ $t('dashboard.topAnbieter.10y').toUpperCase() }}</span
              >
              <span v-else>
                {{ $t('dashboard.topAnbieter.12m').toUpperCase() }}</span
              >
            </th>
            <th class="base-bold-color inter">
              {{ $t('dashboard.topAnbieter.competitor').toUpperCase() }}
            </th>
          </tr>
        </thead>
        <tbody v-if="validate">
          <tr
            v-for="(item, index) in toggleOne === 'kurz'
              ? dashboardTop.festgeldTopZinsen
              : dashboardTop.festgeldLang"
            :key="index"
          >
            <td>
              <span v-if="index === 0" class="dashboard-top-anbieter inter"
                >#{{ index + 1 }}</span
              >
              <span v-else class="base-grey rest-anbieter inter"
                >#{{ index + 1 }}</span
              >
            </td>
            <td class="fmh-green text-no-wrap">
              <span v-if="item.zinssatz1" class="inter"
                >{{ value(item.zinssatz1) }} %</span
              >
              <span v-else>-</span>
            </td>
            <td class="fmh-green text-no-wrap">
              <span v-if="item.zinssatz2" class="inter"
                >{{ value(item.zinssatz2) }} %</span
              >
              <span v-else>-</span>
            </td>
            <td class="fmh-green text-no-wrap">
              <span v-if="item.zinssatz3" class="inter"
                >{{ value(item.zinssatz3) }} %</span
              >
              <span v-else>-</span>
            </td>
            <td class="base-grey-bold inter">
              {{ item.anbietername.toUpperCase() }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="inter">{{ $t('dashboard.topAnbieter.noData') }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card-actions class="justify-center">
      <v-btn
        class="btn"
        :color="color"
        @click="$router.push('/festgeld/wizard')"
        >{{ $t('general.actionBtn.createEvaluation') }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { BASE_BLACK_COLOR } from '@/utils/constants';

@Component({
  computed: {
    ...mapGetters({
      dashboardTop: 'reports/getDashboardTop',
    }),
  },
})
export default class TopFestgeldZinsen extends Vue {
  toggleOne = 'kurz';
  color = BASE_BLACK_COLOR;

  get validate() {
    return this.toggleOne === 'kurz'
      ? !!this.dashboardTop.festgeldTopZinsen.length
      : !!this.dashboardTop.festgeldLang.length;
  }
  value(val) {
    return Number(val.toFixed(2)).toLocaleString('de-DE', {
      minimumFractionDigits: 2,
    });
  }
}
</script>

<style lang="scss">
.btn {
  color: #fff;
}
</style>
