


































































import { Component, Vue } from 'vue-property-decorator';
import { COLOR } from '@/utils/constants';

@Component({
  components: {},
})
export default class FmhService extends Vue {
  color = COLOR;
}
