


















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import {
  BASE_BLACK_COLOR,
  LIVE_DATA_CONFIG,
  WETTBEWERB_CONFIG,
} from '@/utils/constants';

import Dialog from '@/components/fmh_components/common/Dialog/Dialog.vue';

@Component({
  components: { Dialog },
  computed: {
    ...mapGetters({
      permissions: 'auth/getPermissions',
    }),
  },
})
export default class FirstWelcome extends Vue {
  @Prop() user: Object | undefined;

  permissions: Array<string> | undefined;
  color = BASE_BLACK_COLOR;
  modal = false;
  liveDataModel = {
    permission: '',
    link: '',
  };
  wettbewerbModel = {
    permission: '',
    link: '',
  };
  liveData = LIVE_DATA_CONFIG;
  wettbewerb = WETTBEWERB_CONFIG;

  @Watch('wettbewerbModel') validateWettbewerb() {
    if (this.permissions?.includes(this.wettbewerbModel.permission)) {
      this.$router.push(this.wettbewerbModel.link);
    } else {
      this.modal = true;
    }
  }
  @Watch('liveDataModel') validateLiveData() {
    if (this.permissions?.includes(this.liveDataModel.permission)) {
      this.$router.push(this.liveDataModel.link);
    } else {
      this.modal = true;
    }
  }
  handleChangeModal(val) {
    this.modal = val;
  }
}
